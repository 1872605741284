<template>
  <RouterLink to="/" class="logo"> <span>Ecoplanet</span> </RouterLink>
</template>

<style lang="scss" scoped>
.logo {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  color: $black;
  text-decoration: none;
  &:hover {
    color: $black;
  }
  span {
    color: $primary;
    line-height: 30px;
  }
}
</style>
