import http from "./http";
import { LoginParams } from "./model/userModel";

enum Api {
  Login = "/admin/login",
  Logout = "/logout",
  getInfo = "/admin/myInfo",
  GetAdmins = "/admin/admins",
  GetAdminInfo = "/admin/adminInfo",
  EditPassword = "/admin/passwordEdit",
  GetPermCode = "/getPermCode",

  AddAdmin = "/admin/adminAdd",
  EditAdmin = "/admin/adminEdit",

  GetMemos = "/admin/memos",
  AddMemo = "/admin/memoAdd",
  DelMemo = "/admin/memoDel",
}

/**
 * @description: admin login api
 */

export async function loginApi(params: LoginParams) {
  return await http.post(Api.Login, params);
}

/**
 * @description: get my info data
 */
export async function getInfoApi(params: object) {
  return await http.post(Api.getInfo, params);
}

/**
 * @description: get admins data info api
 */

export async function getAdminsApi(params: any) {
  return await http.post(Api.GetAdmins, params);
}

/**
 * @description: get admin data info api
 */

export async function getAdminInfoApi(params: any) {
  return await http.post(Api.GetAdminInfo, params);
}

/**
 * @description: add admin api
 */

export async function addAdminApi(params: any) {
  return await http.post(Api.AddAdmin, params);
}

/**
 * @description: edit admin api
 */

export async function editAdminApi(params: any) {
  return await http.post(Api.EditAdmin, params);
}

/**
 * @description: edit admin password api
 */

export async function editPasswordApi(params: any) {
  return await http.post(Api.EditPassword, params);
}

/**
 * @description: get memos data info api
 */

export async function getMemosApi(params: any) {
  return await http.post(Api.GetMemos, params);
}

/**
 * @description: add memo api
 */

export async function addMemoApi(params: any) {
  return await http.post(Api.AddMemo, params);
}

/**
 * @description: delete memo api
 */

export async function delMemoApi(params: any) {
  return await http.post(Api.DelMemo, params);
}
