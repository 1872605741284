import store from "@/store";
import _uniqBy from "lodash/unionBy";
import { computed } from "vue";
import {
  getContentsApi,
  getSessionsApi,
  getSessionInfoApi,
} from "@/api/sys/battle";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    contents: [],
    sessions: [],
    theSession: {},
    session_totalCnt: 0,
    contents_totalCnt: 0,
    session_searchParams: {
      banner: "",
      title: "",
      topic: "",
      start_day: "",
      end_day: "",
    },
    content_searchParams: {
      sessionId: "",
      uid: "",
      address: "",
      image: "",
      title: "",
      desc: "",
      vote: "",
      start_day: "",
      update_day: "",
    },
  },
  getters: {
    contents: (state: Nullable) => state.contents,
    sessions: (state: Nullable): Array<any> => state.sessions,
    theSession: (state: Nullable): Array<any> => state.theSession,
    session_totalCnt: (state: Nullable): number => state.session_totalCnt,
    contents_totalCnt: (state: Nullable): number => state.contents_totalCnt,
    session_searchParams: (state: Nullable): Array<any> =>
      state.session_searchParams,
    content_searchParams: (state: Nullable): Array<any> =>
      state.content_searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
    // getContents(state: Nullable, data: Nullable) {
    //   console.log("datadata", data);
    //   state.contents = data;
    // },
  },
  actions: {
    async searchSessions(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchSession({
          ...payload,
        });

        if (res.status === 200) {
          const { sessions, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            sessions: _uniqBy(sessions, "idx"),
            session_totalCnt: totalCnt,
            session_searchParams: {
              banner: payload.banner,
              title: payload.title,
              topic: payload.topic,
              start_day: payload.start_day,
              end_day: payload.end_day,
            },
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          sessions: [],
        });
      } finally {
        state.commit("updateStatus", {
          theSession: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    async searchContents(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }

      try {
        const res = await _findContents({
          ...payload,
        });
        // const res = await getContentsApi();

        if (res.status === 200) {
          const { data, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,

            contents: data,
            contents_totalCnt: totalCnt,
            content_searchParams: {
              sessionId: payload.sessionId,
              uid: payload.uid,
              address: payload.address,
              image: payload.image,
              title: payload.title,
              desc: payload.desc,
              vote: payload.vote,
              start_day: payload.start_day,
              update_day: payload.update_day,
            },
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theSession: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },

    async findSession(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findSession({
          ...payload,
        });

        if (res.status === 200) {
          const { session } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theSession: session,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theSession: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};

async function _findContents(payload: any) {
  return await getContentsApi(payload);
}

async function _fetchSession(payload: any) {
  return await getSessionsApi(payload);
}

async function _findSession(payload: any) {
  return await getSessionInfoApi(payload);
}
