import { loginApi, getInfoApi } from "@/api/sys/admin";
import { GetAdminInfoModel, LoginParams } from "@/api/sys/model/userModel";
import { encryptByMd5 } from "@/utils/util";
import store from "@/store";
import { log } from "console";
import common from "@/common/common";
// import { useStore } from "vuex";
// const store = useStore();

export default {
  namespaced: true,
  state: {
    sidebarStatus: false,
    loading: false,
    info: {
      name: "",
      sessionId: "",
      accessToken: "",
      accessExpiresIn: 0,
    },
  },
  getters: {
    sidebarFlag: (state: Nullable): boolean => state.sidebarStatus,
    appLoading: (state: Nullable): boolean => state.loading,
    myName: (state: Nullable): string => state.info.name,
    mySessionId: (state: Nullable): string => state.info.sessionId,
    myAccessToken: (state: Nullable): string => state.info.accessToken,
    myAccessExpiresIn: (state: Nullable): number => state.info.accessExpiresIn,
    ChkAccessExpiresIn: (state: Nullable): boolean =>
      new Date().getTime() > state.info.accessExpiresIn,
  },
  mutations: {
    UPDATE_SIDEBARSTATUS(state: Nullable) {
      state.sidebarStatus = !state.sidebarStatus;
    },
    UPDATE_LOADING(state: Nullable, val?: boolean) {
      if (val != null) {
        state.loading = val;
      } else {
        state.loading = !state.loading;
      }
    },
    RESET_INFO(state: Nullable) {
      state.info.name = "";
      state.info.sessionId = "";
      state.info.accessToken = "";
      state.info.accessExpiresIn = 0;
    },
    UPDATE_INFO(state: Nullable, data: any) {
      state.info.name = data["name"];
      state.info.sessionId = data["sessionId"];
      state.info.accessToken = data["accessToken"];
      state.info.accessExpiresIn = data["accessExpiresIn"];
    },
  },
  actions: {
    //로그인
    async login(state: Nullable, payload: LoginParams) {
      if (state.loading) {
        return;
      } else {
        state.commit("UPDATE_LOADING", true);
      }
      // 입력 정보 Md5로 인코딩
      const loginData: LoginParams = {
        account: encryptByMd5(payload.account),
        password: encryptByMd5(payload.password),
      };
      try {
        const result = await loginApi(loginData);
        if (result.status === 200) {
          const loginData: GetAdminInfoModel = result.data.data;
          state.commit("UPDATE_INFO", {
            name: loginData.name,
            sessionId: loginData.sessionId,
            accessToken: loginData.accessToken,
            accessExpiresIn:
              new Date().getTime() + loginData.accessExpiresIn * 1000,
          });

          const newAdmininfo = {
            aid: loginData.aid,
            account: loginData.account,
            appId: loginData.appId,
            tel: loginData.tel,
            status: loginData.status,
            name: loginData.name,
            grade: loginData.grade,
            grade1: loginData.grade1,
            grade2: loginData.grade2,
            grade3: loginData.grade3,
            grade4: loginData.grade4,
            grade5: loginData.grade5,
            sessionId: loginData.sessionId,
            makerAid: loginData.makerAid,
            created_date: loginData.created_date,
            updated_date: loginData.updated_date,
            token: loginData.accessToken,
          };
          store.commit("admin/updateStatus", newAdmininfo);

          return { result: true, message: "success" };
        }
      } catch (e: any) {
        const message: string = common.axiosCatch(e);
        return { result: false, message: message };
      } finally {
        state.commit("UPDATE_LOADING", false);
      }
    },
  },
};
