import http from "./http";

enum Api {
  GetChains = "/admin/chains",
  GetChainInfo = "/admin/chainInfo",
  AddChain = "/admin/chainAdd",
  EditChain = "/admin/chainEdit",

  GetExchanges = "/admin/exchanges",

  GetTokens = "/admin/tokens",
  GetTokenInfo = "/admin/tokenInfo",
  AddToken = "/admin/tokenAdd",
  EditToken = "/admin/tokenEdit",

  GetNfts = "/admin/nfts",
  GetNftInfo = "/admin/nftInfo",
  AddNft = "/admin/nftAdd",
  EditNft = "/admin/nftEdit",

  GetUserNfts = "/admin/userNfts",
  GetUserGTs = "/admin/userGTs",
  GetUserRewards = "/admin/userRewards",

  deletenftCategory = "/admin/nftDel",
}

/**
 * @description: get chains data info api
 */

export async function getChainsApi(params: any) {
  return await http.post(Api.GetChains, params);
}
/**
 * @description: get chain data info api
 */

export async function getChainInfoApi(params: any) {
  return await http.post(Api.GetChainInfo, params);
}

/**
 * @description: add chain api
 */

export async function addChainApi(params: any) {
  return await http.post(Api.AddChain, params);
}

/**
 * @description: edit chain api
 */

export async function editChainApi(params: any) {
  return await http.post(Api.EditChain, params);
}

/**
 * @description: get exchanges data info api
 */

export async function getExchangesApi(params: any) {
  return await http.post(Api.GetExchanges, params);
}

/**
 * @description: get tokens data info api
 */

export async function getTokensApi(params: any) {
  return await http.post(Api.GetTokens, params);
}
/**
 * @description: get token data info api
 */

export async function getTokenInfoApi(params: any) {
  return await http.post(Api.GetTokenInfo, params);
}

/**
 * @description: add token api
 */

export async function addTokenApi(params: any) {
  return await http.post(Api.AddToken, params);
}

/**
 * @description: edit token api
 */

export async function editTokenApi(params: any) {
  return await http.post(Api.EditToken, params);
}

/**
 * @description: get nfts data info api
 */

export async function getNftsApi(params: any) {
  return await http.post(Api.GetNfts, params);
}
/**
 * @description: get nft data info api
 */

export async function getNftInfoApi(params: any) {
  return await http.post(Api.GetNftInfo, params);
}

/**
 * @description: add nft api
 */

export async function addNftApi(params: any) {
  return await http.post(Api.AddNft, params);
}

/**
 * @description: edit nft api
 */

export async function editNftApi(params: any) {
  return await http.post(Api.EditNft, params);
}

/**
 * @description: get user nfts data info api
 */

export async function getUserNftsApi(params: any) {
  return await http.post(Api.GetUserNfts, params);
}

/**
 * @description: get user gameToken data info api
 */

export async function getUserGTsApi(params: any) {
  return await http.post(Api.GetUserGTs, params);
}

/**
 * @description: get user reward data info api
 */

export async function getUserRewardsApi(params: any) {
  return await http.post(Api.GetUserRewards, params);
}

//nft 리스트 삭제
export async function deletenftCategoryApi(idx: any) {
  console.log("deletenftCategory");
  return await http.delete(`${Api.deletenftCategory}/${idx}`);
}
