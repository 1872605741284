import http from "./http";

enum Api {
  GetTxHistories = "/admin/txHistories",
  GetTxSwap = "/admin/txSwap",
}

/**
 * @description: get transactions histories data info api
 */

export async function getTxHistoriesApi(params: any) {
  return await http.post(Api.GetTxHistories, params);
}
/**
 * @description: get swap transactions data info api
 */

export async function getTxSwapApi(params: any) {
  return await http.post(Api.GetTxSwap, params);
}
