import dayjs from "dayjs";
import locale from "@/locale";
// import { useStore } from "vuex";
import store from "@/store";

export default {
  changeStatusVal(payload: any) {
    if (typeof payload == "string") {
      if (payload == locale.global.t("board.normalcy")) {
        payload = 0;
      } else {
        payload = 1;
      }
    } else if (typeof payload == "number") {
      if (payload == 0) {
        payload = locale.global.t("board.normalcy");
      } else {
        payload = locale.global.t("board.block");
      }
    } else if (typeof payload == "object") {
      payload.map(function (e: any) {
        if (typeof e.status == "number") {
          if (e.status == 0) {
            e.status = locale.global.t("board.normalcy");
          } else {
            e.status = locale.global.t("board.block");
          }
        } else {
          if (e.status == locale.global.t("board.normalcy")) {
            e.status = 0;
          } else {
            e.status = 1;
          }
        }
        e.created_date = dayjs(e.created_date).format("YYYY-MM-DD HH:mm:ss");
        e.createdAt = dayjs(e.createdAt).format("YYYY-MM-DD HH:mm:ss");
      });
    }
    return payload;
  },
  chainOption(payload: any) {
    const arr: Array<any> = [
      {
        text: locale.global.t("board.write.placeholder.chain_select"),
        value: "",
      },
    ];
    payload.map(function (e: any) {
      arr.push({ text: e.chainId, value: e.chainId });
    });
    return arr;
  },
  exchangeOption(payload: any) {
    const arr: Array<any> = [
      {
        text: locale.global.t("board.write.placeholder.exchange_select"),
        value: "",
      },
    ];
    payload.map(function (e: any) {
      arr.push({ text: e.type, value: e.type });
    });
    return arr;
  },
  tokenOption(payload: any) {
    const arr: Array<any> = [
      { text: locale.global.t("board.write.placeholder.token"), value: "" },
    ];
    payload.map(function (e: any) {
      if (e.type == "T") {
        arr.push({ text: e.name, value: e.idx });
      }
    });
    return arr;
  },
  formValue(data: any, str: string) {
    return data.value.filter((item: any) => item.name == str)[0].value;
  },
  //한글자르기
  cutHan(str: string, len: number, tail = "") {
    if (str != null) {
      if (str.length > len) {
        for (let index = 0; index < len; index++) {
          if (str.charCodeAt(index) > 127) index += 2;
          str = str.substring(0, len);
          str += tail;
        }
      }
    }
    return str;
  },
  //API catch 값 체크
  axiosCatch(e: any) {
    let message = "";
    if (e.response != undefined) {
      if (e.response.status === 401) {
        store.commit("app/RESET_INFO");
      }
      message = e.response.data.message;
    } else if (e.code == "ERR_NETWORK") {
      message = "Please check the server.";
      store.commit("app/RESET_INFO");
    }
    return message;
  },
  // 클립보드로 복사
  copy_to_clipboard(event: any) {
    /* if (!navigator.clipboard) {
			alert("복사가 지원되지 않는 브라우저 입니다. 직접 선택하여 복사해주세요.")
			return
		} */
    const copyText = event.target.name;
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        console.log("Text copied to clipboard...");
        event.target.nextElementSibling.className = "check-on";
        setTimeout(function () {
          event.target.nextElementSibling.className = "check-off";
        }, 1000);
      })
      .catch((err) => {
        console.log("Something went wrong", err);
      });
  },
  /* 	TYPE_TRANSACTION_SEND_COIN: 0,
	TYPE_TRANSACTION_APPROVE_COIN: 1,
	TYPE_TRANSACTION_MINT_COIN: 2,
	TYPE_TRANSACTION_STAKE_COIN: 3,
	TYPE_TRANSACTION_UNSTAKE_COIN: 4,
	TYPE_TRANSACTION_REWARD_COIN: 5,
	TYPE_TRANSACTION_SWAP_COIN: 6,
	TYPE_TRANSACTION_BURN_COIN: 7,
	TYPE_TRANSACTION_DISTRIBUTE_REWARD: 10, */
  getTypeTransactionName(type: number) {
    switch (type) {
      case 0:
        return "SEND";
      case 1:
        return "APPROVE";
      case 2:
        return "MINT";
      case 3:
        return "STAKE";
      case 4:
        return "UNSTAKE";
      case 5:
        return "REWARD";
      case 6:
        return "SWAP";
      case 7:
        return "BURN";
      case 10:
        return "DISTRIBUTE_REWARD";
      default:
        return type;
    }
  },
  /*
  METHOD_TRANSFER: 0,
	METHOD_APPROVE: 1,
	METHOD_MINTNFT: 2,
	METHOD_STAKE: 3,
	METHOD_UNSTAKE: 4,
	METHOD_REWARD: 5,
	METHOD_DISTRIBUTE: 6,
	METHOD_BURN: 7,
	METHOD_SWAP: 10,
   */
  getMethodName(type: number) {
    switch (type) {
      case 0:
        return "TRANSFER";
      case 1:
        return "APPROVE";
      case 2:
        return "MINTNFT";
      case 3:
        return "STAKE";
      case 4:
        return "UNSTAKE";
      case 5:
        return "REWARD";
      case 6:
        return "DISTRIBUTE";
      case 7:
        return "BURN";
      case 10:
        return "SWAP";
      default:
        return type;
    }
  },
  /*
	// swap status
	STATUS_SWAP_SEND: 0, 
	STATUS_SWAP_RECV: 1, 
	STATUS_SWAP_CONFIRM: 2, 
	STATUS_SWAP_FINISH: 3, 
  STATUS_SWAP_SEND_ERROR: 10,
	STATUS_SWAP_RECV_ERROR: 11,
   */
  getSwapStatusName(type: number) {
    switch (type) {
      case 0:
        return "SEND";
      case 1:
        return "RECV";
      case 2:
        return "CONFIRM";
      case 3:
        return "FINISH";
      case 10:
        return "SEND_ERROR";
      case 11:
        return "RECV_ERROR";
      default:
        return type;
    }
  },
  isEmptyObj(obj: object) {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
      return true;
    }
    return false;
  },
};
