import { computed } from "vue";
import _uniqBy from "lodash/unionBy";
import LS from "@/utils/localStorage";
import store from "@/store";
import { getNoticeInfoApi, getNoticesApi } from "@/api/sys/notice";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    idx: "" || 0,
    type: 0,
    title: "",
    desc: "",
    hide: "",
    createdAt: Date,
    updatedAt: Date,
    notices: [],
    theNotice: {},
    totalCnt: 0,
    searchParams: {
      type: "",
      hide: "",
      title: "",
      desc: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    notices: (state: Nullable): Array<any> => state.notices,
    theNotice: (state: Nullable): Array<any> => state.theNotice,
    totalCnt: (state: Nullable): number => state.totalCnt,
    searchParams: (state: Nullable): Array<any> => state.searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    //특정 공지 정보 검색
    async findNotice(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findNotice({
          ...payload,
        });

        if (res.status === 200) {
          const { notice } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theNotice: notice,
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theNotice: {},
        });
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    // 공지 정보 검색
    async searchNotices(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchNotice({
          ...payload,
        });

        if (res.status === 200) {
          const { notices, totalCnt } = res.data;

          state.commit("updateStatus", {
            notices: _uniqBy(notices, "idx"),
            theNotice: {},
            totalCnt: totalCnt,
            searchParams: payload,
          });
          // const total = parseInt(totalCnt, 10);
          // const pagelenth = Math.ceil(total / 10);
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          notices: [],
        });
      } finally {
        state.commit("updateStatus", {
          theNotice: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _findNotice(payload: any) {
  return await getNoticeInfoApi(payload);
}
async function _fetchNotice(payload: any) {
  return await getNoticesApi(payload);
}
