export default {
  getItem: (key: any) => {
    const val = localStorage.getItem(key) || "";

    let res: any = "";
    try {
      res = JSON.parse(val);
      // const _value = JSON.parse(res);
      // 만료일 체크, 날짜 지나면 데이터 삭제
      if (res && res.expiration && new Date().getTime() > res.expiration) {
        localStorage.removeItem(key);
        return "";
      } else {
        return res.data;
      }
    } catch (err) {
      res = val;
      return res;
    }
  },
  setItem: (key: any, val: any, time?: number) => {
    let expirationDate = new Date().getTime();
    if (time) {
      expirationDate += time * 1000;
    } else {
      // 설정된 time이 없는 경우 현재 시간 + 100년으로 값 설정
      expirationDate += 36500 * 24 * 60 * 60 * 1000;
    }

    localStorage.setItem(
      key,
      JSON.stringify({ data: val, expiration: expirationDate })
    );
  },
  removeItem: (key: any) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  },
};
