import http from "./http";

enum Api {
  GetBanners = "/admin/banners",
  GetBannerInfo = "/admin/bannerInfo",
  AddBanner = "/admin/BannerAdd",
  EditBanner = "/admin/BannerEdit",
  deleteBanner = "/admin/BannerDel",

  GetCategories = "/admin/categories",
  GetCategoryInfo = "/admin/categoryInfo",
  AddCategory = "/admin/categoryAdd",
  EditCategory = "/admin/categoryEdit",
  deleteCategory = "/admin/CategoryDel",

  getMarketCategory = "/admin/marketCategory",
  deleteMarketCategory = "/admin/marketCategoryDelete",
  addMarketCategory = "/admin/marketCategoryAdd",
  editMarketCategory = "/admin/marketCategoryEdit",

  //referral
  GetReferral = "/admin/referral",
  GetInfoReferral = "/admin/infoReferral",
}

/**
 * @description: get banners data info api
 */

export async function getBannersApi(params: any) {
  return await http.post(Api.GetBanners, params);
}
/**
 * @description: get banner data info api
 */

export async function getBannerInfoApi(params: any) {
  return await http.post(Api.GetBannerInfo, params);
}

/**
 * @description: add banner api
 */

export async function addBannerApi(params: any) {
  return await http.post(Api.AddBanner, params);
}

/**
 * @description: edit banner api
 */

export async function editBannerApi(params: any) {
  return await http.post(Api.EditBanner, params);
}

/**
 * @description: delete banner api
 */
export async function deleteBannerApi(idx: any) {
  console.log("deleteBanner");
  return await http.delete(`${Api.deleteBanner}/${idx}`);
}

/**
 * @description: get categories data info api
 */

export async function getCategoriesApi(params: any) {
  return await http.post(Api.GetCategories, params);
}
/**
 * @description: get category data info api
 */

export async function getCategoryInfoApi(params: any) {
  return await http.post(Api.GetCategoryInfo, params);
}

/**
 * @description: add category api
 */

export async function addCategoryApi(params: any) {
  return await http.post(Api.AddCategory, params);
}

/**
 * @description: edit category api
 */

export async function editCategoryApi(params: any) {
  return await http.post(Api.EditCategory, params);
}

/**
 * @description: delete category api
 */

export async function deleteCategoryApi(idx: any) {
  console.log("deleteCategory");
  return await http.delete(`${Api.deleteCategory}/${idx}`);
}

//market Category

/**
 * @description: get market category data api
 */

export async function getMarketCategoryApi(params: any) {
  return await http.post(Api.getMarketCategory, params);
}

/**
 * @description: add market category api
 */

export async function addMarketCategoryApi(params: any) {
  return await http.post(Api.addMarketCategory, params);
}

/**
 * @description: edit market category api
 */

export async function editMarketCategoryApi(params: any) {
  return await http.post(Api.editMarketCategory, params);
}

/**
 * @description: delete market category api
 */

export async function deleteMarketCategoryApi(params: any) {
  return await http.post(Api.deleteMarketCategory, params);
}

//Userreferral
export async function getUserReferralApi(payload: any) {
  return await http.post(Api.GetReferral, payload);
}

//infoRferral
export async function getInfoReferralApi(payload: any) {
  return await http.post(Api.GetInfoReferral, payload);
}
