import { computed } from "vue";
import { getTxHistoriesApi, getTxSwapApi } from "@/api/sys/transaction";
import _uniqBy from "lodash/unionBy";
import store from "@/store";
import common from "@/common/common";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    txHistories: [],
    txHistories_totalCnt: 0,
    txHistories_searchParams: {
      chainId: "",
      txid: "",
      from: "",
      to: "",
      blockNumberConfirm: "",
      status: "",
      typeTransaction: "",
      method: "",
      start_day: "",
      end_day: "",
    },
    txSwaps: [],
    txSwaps_totalCnt: 0,
    txSwaps_searchParams: {
      fromTxId: "",
      fromSymbol: "",
      fromAddress: "",
      toTxId: "",
      toSymbol: "",
      toAddress: "",
      method: "",
      status: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    txHistories: (state: Nullable): Array<any> => state.txHistories,
    txHistories_totalCnt: (state: Nullable): number =>
      state.txHistories_totalCnt,
    txHistories_searchParams: (state: Nullable): Array<any> =>
      state.txHistories_searchParams,
    txSwaps: (state: Nullable): Array<any> => state.txSwaps,
    txSwaps_totalCnt: (state: Nullable): number => state.txSwaps_totalCnt,
    txSwaps_searchParams: (state: Nullable): Array<any> =>
      state.txSwaps_searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    // 트렌젝션 히스토리 검색
    async searchTxHistories(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchTxHistories({
          ...payload,
        });

        if (res.status === 200) {
          const { txHistories, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            txHistories: _uniqBy(txHistories, "idx"),
            txHistories_totalCnt: totalCnt,
            txHistories_searchParams: payload,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    //스왑 트렌젝션 검색
    async searchTxSwap(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchTxSwap({
          ...payload,
        });

        if (res.status === 200) {
          const { txSwaps, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            txSwaps: _uniqBy(txSwaps, "idx"),
            txSwaps_totalCnt: totalCnt,
            txSwaps_searchParams: payload,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _fetchTxHistories(payload: any) {
  return await getTxHistoriesApi(payload);
}
async function _fetchTxSwap(payload: any) {
  return await getTxSwapApi(payload);
}
