export default {
  info: {
    name: "ZeroQuest",
    title: "ZeroQuest WALLET",
  },
  common: {
    // S Footer
    contact: "연락",
    docs: "문서",
    audits: "감사",
    // E Footer
    undefined: "-",
    success: "성공",
    noData: "No Data",
    price: "가격",
    contractAddress: "계약 주소",
    description: "설명",
    id: "ID",
    balance: "수량",
    status: "상황",
    congratulations: "축하합니다!",
    login: "로그인",
    loading: "Loading",
    logout: "로그아웃",
  },

  board: {
    topic: "주제",
    number: "번호",
    session_id: "세션ID",
    uid: "uid",
    id: "ID",
    name: "이름",
    vote: "투표수",
    username: "유저이름",
    phoneNumber: "전화번호",
    email: "이메일",
    referralBalance: "추천 보너스",
    referral: "추천인 코드",
    from: "보내는 사람 주소",
    to: "받는사람 주소",
    shareCount: "추천하기 횟수",
    userState: "유저상태",
    imageFile: "이미지 파일",
    state: "상태",
    normalcy: "정상",
    block: "블럭",
    joinDate: "가입한날짜",
    list: "리스트",
    register: "등록",
    modify: "수정",
    delete: "삭제",
    total_search_data: "총 검색 데이터: ",
    number_cases: "건",
    prev: "이전",
    next: "다음",
    no_data: "검색조건에 맞는 데이타가 없습니다.",
    type: "타입",
    title: "제목",
    desc: "내용",
    hide: "숨김 여부",
    display: "표시여부",
    visible: "공개",
    invisible: "비공개",
    createdDate: "등록날짜",
    updateDate: "업데이트날짜",
    endDate: "종료날짜",
    notification: "공지",
    guide: "안내",
    emergency: "긴급",
    memo: "메모",
    address: "주소",
    chain: "체인",
    url: "URL",
    token_name: "토큰이름",
    symbol: "심볼",
    nft_symbol: "NFT 심볼",
    decimals: "자리수",
    priceDecimals: "가격자리수",
    icon: "아이콘",
    exchange: "거래소",
    pairKey: "거래소 페어키",
    priceUsdt: "USDT 고정가격",
    priceKrw: "KRW 고정가격",
    contractAddress: "컨트렉트 주소",
    nft: "NFT",
    root: "Root",
    point: "포인트",
    token: "Token",
    url_img: "이미지 URL",
    link: "링크주소",
    width: "너비",
    height: "높이",
    code: "코드",
    chainId: "네트워크",
    txId: "TxId",
    blockNumberConfirm: "블록번호",
    status: "상태",
    typeTransaction: "트랙젝션 유형",
    method: "메서드",
    gasPrice: "가스가격",
    value: "개수",
    txIdSend: "txIdSend",
    fromTxId: "보낸 Txid",
    fromSymbol: "보낸코인",
    fromAddress: "보낸 주소",
    fromValue: "보낸개수",
    fromFee: "보낸 수수료",
    toTxId: "받는 Txid",
    toSymbol: "받은코인",
    toAddress: "받은 주소",
    toValue: "받은개수",
    toFee: "받는 수수료",
    normal: "일반",
    nft_on: "On Nft",
    gameNft_on: "On GameNFT",
    impossible: "불가능",
    possible: "가능",
    description: "설명",
    descImage: "설명 이미지",
    inputImage: "이미지 파일",
    buySymbol: "구매가능 심볼",
    buyPrice: "구매가격",
    metaData: "메타데이터",
    cid: "카테고리",
    multiBuy: "중복구매여부",
    image: "이미지",
    category: "카테고리",
    balance: "금액",
    enable: "활성화 여부",
    activate: "활성화",
    disabled: "비활성화",
    tokenId: "토큰 ID",
    nftName: "NFT 이름",
    gameToken: "게임토큰",
    gameKey: "게임키",
    reward: "보상",
    isApply: "적용여부",
    waiting: "대기중",
    complete: "완료",
    cancel: "취소",
    senderName: "등록 식별자",
    senderAidName: "등록 관리자",
    makerAidName: "최근 수정 관리자",
    delete_confirm: "삭제 하시겠습니까?",
    cancel_confirm: "취소 처리 하시겠습니까?",
    create: "생성",
    exist: "확인",
    confirm: "승인",
    finish: "완료",
    terms: "약관",
    agreement: "동의",
    disagree: "비동의",
    termsAt: "약관 동의날짜",
    // createdDate: "등록날짜",
    // updateDate: "업데이트날짜",
    search: {
      period_search_setting: "기간검색 설정",
      search_start_date: "검색 기간 시작 날짜",
      search_end_date: "검색 기간 종료 날짜",
    },
    write: {
      id: "계정 아이디",
      pwd: "계정비밀번호",
      pwd_check: "계정 비밀번호 확인",
      name: "이름",
      tel: "연락처",
      email: "이메일",
      status: "상태여부",
      token: "토큰",
      type: "타입",
      hide: "숨김 여부",
      display: "표시 여부",
      title: "제목",
      topic: "주제",
      desc: "내용",
      chain: "체인",
      url: "URL",
      token_name: "토큰 이름",
      endDate: "종료날짜",
      symbol: "심볼",
      nft_symbol: "NFT 심볼",
      decimals: "코인 소수점 자리수",
      priceDecimals: "가격 소수점 자리수",
      icon: "아이콘",
      exchange: "거래소",
      pairKey: "거래소 페어키",
      priceUsdt: "USDT 고정가격",
      priceKrw: "KRW 고정가격",
      contractAddress: "컨트렉트 주소",
      url_img: "이미지 url",
      link: "링크주소",
      width: "너비",
      height: "높이",
      code: "코드",
      cid: "카테고리",
      multiBuy: "중복구매여부",
      description: "설명",
      descImage: "설명 이미지",
      inputImage: "이미지 파일",
      buySymbol: "구매가능 심볼",
      buyPrice: "구매가격",
      metaData: "메타데이터",
      senderName: "등록 식별자",
      balance: "금액",
      user_check: "유저 체크",
      callbackUrl: "앱업체 데이터 연동 콜백URL",
      andPackagename: "안드로이드 패키지명",
      andDeeplink: "안드로이드 딥링크",
      iosPackagename: "IOS 패키지명",
      iosDeeplink: "IOS 딥링크",
      selectFile: "파일 선택",
      placeholder: {
        id: "계정 아이디를 입력하세요.",
        pwd: "계정비밀번호를 입력하세요.",
        pwd_check: "계정비밀번호를 다시 업력해주세요.",
        name: "이름을 입력하세요.",
        tel: "연락처를 입력하세요.",
        email: "이메일을 입력하세요.",
        status: "상태를 선택하세요.",
        token: "토큰을 선택하세요.",
        type: "타입을 선택하세요.",
        hide: "숨김여부를 선택하세요.",
        display: "표시여부를 선택하세요.",
        title: "제목를 입력하세요.",
        desc: "내용을 입력하세요.",
        memo: "메모내용을 입력하세요.",
        cur_password: "현재 비밀번호를 입력하세요.",
        new_password: "수정할 비밀번호를 입력하세요.",
        cf_password: "수정할 비밀번호를 다시 입력하세요.",
        chain: "체인명을 입력하세요.",
        chain_select: "체인을 선택하세요.",
        url: "URL을 입력하세요.",
        token_name: "토큰 이름을 입력하세요",
        symbol: "심볼이름을 입력하세요.",
        nft_symbol: "NFT 심볼이름을 입력하세요.",
        decimals: "코인 소수점 자리수를 입력하세요.",
        priceDecimals: "가격 소수점 자리수를 입력하세요.",
        icon: "아이콘 이미지 url를 입력하세요.",
        exchange: "거래소 이름을 입력하세요.",
        exchange_select: "거래소를 선택하세요.",
        pairKey: "거래소 페어키를 입력하세요.",
        priceUsdt: "USDT 고정 가격을 입력하세요.",
        priceKrw: "KRW 고정 가격을 입력하세요.",
        contractAddress: "컨트렉트 주소를 입력하세요.",
        url_img: "이미지 URL을 입력하세요.",
        link: "링크주소를 입력하세요.",
        width: "배너 너비를 입력하세요.",
        height: "배너 높이를 입력하세요.",
        code: "코드를 입력하세요",
        description: "설명을 입력하세요.",
        descImage: "설명 이미지 url을 입력하세요.",
        inputImage: "이미지 파일을 넣어주세요.",
        buySymbol: "구매에 필요한 심볼을 입력하세요.",
        buyPrice: "구매 가격을 입력하세요.",
        metaData: "메타 데이터를 입력하세요.",
        balance: "금액 입력시 - 일 경우 포인트가 차감 됩니다.",
        senderName: "등록 식별자를 입력하세요",
        user_check: "유저 이메일을 입력 후 사용자 확인을 해주세요.",
        callbackUrl:
          "앱업체와 데이터 연동이 필요한 경우 콜백 URL(업체제공) 을 입력해주세요",
        andPackagename: "안드로이드 패키지명이 있는 경우 입력하세요.",
        andDeeplink: "안드로이드 딥링크가 있는 경우 입력하세요.",
        iosPackagename: "IOS 패키지명이 있는 경우 입력하세요.",
        iosDeeplink: "IOS 딥링크가 있는 경우 입력하세요.",
      },
      process: {
        register_complete: "등록이 완료 되었습니다.",
        modify_complete: "수정이 완료 되었습니다.",
        delete_complete: "삭제가 완료 되었습니다.",
        register_failed: "등록이 실패 되었습니다.",
        modify_failed: "수정이 실패 되었습니다.",
        delete_failed: "삭제가 실패 되었습니다.",
        user_confirmation: "유저가 확인되었습니다.",
        change_complete: "변경이 완료 되었습니다.",
        change_failed: "변경에 실패 했습니다.",
      },
      error: {
        id_length: "계정 아이디를 5 ~ 15자 이내로 입력하세요.",
        pwd_length: "계정비밀번호를 5자 이상으로 입력하세요..",
        pwd_check: "계정비밀번호가 같지 않습니다. 비밀번호를 확인해 주세요.",
        decimals_number: "코인 소수점 자리수는 숫자로만 입력해주세요.",
        priceDecimals_number: "가격 소수점 자리수는 숫자로만 입력해주세요.",
        email_check: "이메일이 확인되지 않습니다.",
      },
    },
  },

  route: {
    Home: "Home(KR)",
    About: "About(KR)",
    // DEPTH_0 menus (Parent Node)
    P_RootManagent: "관리자 메뉴",
    P_AppManagent: "관리 메뉴",
    P_WalletManagent: "지갑관리",
    P_GroupManagent: "그룹관리",
    P_TransmissionDetails: "전송내역",
    P_BuyCoins: "코인구매",
    P_Config: "설정",
    // DEPTH_1 menus (Child node)
    // RootManagent
    C_AdminList: "관리자계정",
    C_Notice: "공지사항",
    C_ChainInfo: "체인정보",
    C_TokenInfo: "토큰정보",
    C_Memo: "메모",
    C_UserList: "사용자계정",
    // AppManagent
    C_Banner: "배너관리",
    C_Category: "카테고리관리",
    C_NftInfo: "NFT관리",
    C_UserPoint: "유저 포인트 관리",
    C_UserNftList: "유저 NFT 리스트",
    C_UserGameTokenList: "유저 게임토큰 리스트",
    C_UserRewardsList: "유저 보상 리스트",
    C_InfoReferral: "추천인 balance, count 리스트",
    C_UserReferral: "추천인 코드 리스트",
    // TransmissionDetails
    C_TransitionHistory: "전송정보",
    C_SwapTransactions: "스왑정보",
    // GroupManagent
    C_Group_UserList: "그룹사용자계정",
    // BuyCoins
    C_UserTokensList: "유저토큰 리스트",
    C_WithdrwalHistory: "출금내역",
    // Config
    C_AccountInfo: "정보변경",
    C_Password: "비밀번호변경",
    Battle: "배틀",
    BattleSession: "배틀세션",
    BattleContents: "배틀컨텐츠",
  },

  message: {
    sorryChecking: "시스템 점검 중.",
  },
};
