import { computed } from "vue";
import {
  getChainInfoApi,
  getChainsApi,
  getExchangesApi,
  getNftInfoApi,
  getNftsApi,
  getTokenInfoApi,
  getTokensApi,
  getUserGTsApi,
  getUserNftsApi,
  getUserRewardsApi,
} from "@/api/sys/cryptocurrency";
import _uniqBy from "lodash/unionBy";
import store from "@/store";
import common from "@/common/common";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    chains: [],
    theChain: {},
    chain_totalCnt: 0,
    exchanges: [],
    theExchanges: {},
    exchanges_totalCnt: 0,
    tokens: [],
    theToken: {},
    token_totalCnt: 0,
    token_searchParams: {
      chainId: "",
      type: "",
      symbol: "",
      name: "",
      contractAddress: "",
      hide: "",
      market: "",
      start_day: "",
      end_day: "",
    },
    nfts: [],
    theNft: {},
    nft_totalCnt: 0,
    nft_searchParams: {
      cid: "",
      type: "",
      symbol: "",
      name: "",
      description: "",
      buySymbol1: "",
      buySymbol2: "",
      hide: "",
      start_day: "",
      end_day: "",
    },
    userNfts: [],
    theUserNft: {},
    userNft_totalCnt: 0,
    userNft_searchParams: {
      username: "",
      symbol: "",
      nftName: "",
      enable: "",
      start_day: "",
      end_day: "",
    },
    userGTs: [],
    theUserGT: {},
    userGT_totalCnt: 0,
    userGT_searchParams: {
      username: "",
      symbol: "",
      nftName: "",
      gameToken: "",
      start_day: "",
      end_day: "",
    },
    userRewards: [],
    theUserReward: {},
    userReward_totalCnt: 0,
    userReward_searchParams: {
      username: "",
      symbol: "",
      nftName: "",
      gameKey: "",
      reward: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    chains: (state: Nullable): Array<any> => state.chains,
    theChain: (state: Nullable): Array<any> => state.theChain,
    chain_totalCnt: (state: Nullable): number => state.chain_totalCnt,
    exchanges: (state: Nullable): Array<any> => state.exchanges,
    theExchange: (state: Nullable): Array<any> => state.theExchange,
    exchange_totalCnt: (state: Nullable): number => state.exchange_totalCnt,
    tokens: (state: Nullable): Array<any> => state.tokens,
    theToken: (state: Nullable): Array<any> => state.theToken,
    token_totalCnt: (state: Nullable): number => state.token_totalCnt,
    token_searchParams: (state: Nullable): Array<any> =>
      state.token_searchParams,
    nfts: (state: Nullable): Array<any> => state.nfts,
    theNft: (state: Nullable): Array<any> => state.theNft,
    nft_totalCnt: (state: Nullable): number => state.nft_totalCnt,
    nft_searchParams: (state: Nullable): Array<any> => state.nft_searchParams,
    userNfts: (state: Nullable): Array<any> => state.userNfts,
    theUserNft: (state: Nullable): Array<any> => state.theUserNft,
    userNft_totalCnt: (state: Nullable): number => state.userNft_totalCnt,
    userNft_searchParams: (state: Nullable): Array<any> =>
      state.userNft_searchParams,
    userGTs: (state: Nullable): Array<any> => state.userGTs,
    theUserGT: (state: Nullable): Array<any> => state.theUserGT,
    userGT_totalCnt: (state: Nullable): number => state.userGT_totalCnt,
    userGT_searchParams: (state: Nullable): Array<any> =>
      state.userGT_searchParams,
    userRewards: (state: Nullable): Array<any> => state.userRewards,
    theUserReward: (state: Nullable): Array<any> => state.theUserReward,
    userReward_totalCnt: (state: Nullable): number => state.userReward_totalCnt,
    userReward_searchParams: (state: Nullable): Array<any> =>
      state.userReward_searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    // 체인 정보 검색
    async searchChains(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchChain({
          ...payload,
        });

        if (res.status === 200) {
          const { chains, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            chains: _uniqBy(chains, "idx"),
            theChain: {},
            chain_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //특정 체인 정보 검색
    async findChain(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findChain({
          ...payload,
        });

        if (res.status === 200) {
          const { chain } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theChain: chain,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        common.axiosCatch(e);
        state.commit("updateStatus", {
          theChain: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },

    // 거래소 정보 검색
    async searchExchanges(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchExchange({
          ...payload,
        });

        if (res.status === 200) {
          const { exchanges, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            exchanges: _uniqBy(exchanges, "idx"),
            theExchange: {},
            exchange_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    // 토큰 정보 검색
    async searchTokens(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchToken({
          ...payload,
        });

        if (res.status === 200) {
          const { tokens, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            tokens: _uniqBy(tokens, "idx"),
            theToken: {},
            token_totalCnt: totalCnt,
            token_searchParams: {
              chainId: payload.chainId,
              type: payload.type,
              symbol: payload.symbol,
              name: payload.name,
              market: payload.market,
              start_day: payload.start_day,
              end_day: payload.end_day,
              contractAddress: payload.contractAddress,
              hide: payload.hide,
            },
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    //특정 토큰 정보 검색
    async findToken(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findToken({
          ...payload,
        });

        if (res.status === 200) {
          const { token } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theToken: token,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        common.axiosCatch(e);
        state.commit("updateStatus", {
          theToken: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
    // Nft 정보 검색
    async searchNfts(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchNft({
          ...payload,
        });

        if (res.status === 200) {
          const { nfts, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            nft_searchParams: payload,
            nfts: _uniqBy(nfts, "idx"),
            theNft: {},
            nft_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //특정 nft 정보 검색
    async findNft(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findNft({
          ...payload,
        });

        if (res.status === 200) {
          const { nft } = res.data;

          delete Object.assign(nft, { multiBuy: nft.multi_buy })["multi_buy"];
          delete Object.assign(nft, { callbackUrl: nft.callback_url })[
            "callback_url"
          ];
          delete Object.assign(nft, { andPackagename: nft.and_packagename })[
            "and_packagename"
          ];
          delete Object.assign(nft, { andDeeplink: nft.and_deeplink })[
            "and_deeplink"
          ];
          delete Object.assign(nft, { iosPackagename: nft.ios_packagename })[
            "ios_packagename"
          ];
          delete Object.assign(nft, { iosDeeplink: nft.ios_deeplink })[
            "ios_deeplink"
          ];

          state.commit("updateStatus", {
            ...state.state,
            theNft: nft,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        common.axiosCatch(e);
        state.commit("updateStatus", {
          theNft: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
    // User Nft 정보 검색
    async searchUserNfts(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchUserNft({
          ...payload,
        });

        if (res.status === 200) {
          const { userNfts, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            userNfts: _uniqBy(userNfts, "idx"),
            theUserNft: {},
            userNft_searchParams: payload,
            userNft_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    // User gameToken 정보 검색
    async searchUserGTs(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchUserGT({
          ...payload,
        });

        if (res.status === 200) {
          const { userGTs, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            userGTs: _uniqBy(userGTs, "idx"),
            theUserGT: {},
            userGT_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    // User reward 정보 검색
    async searchUserRewards(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchUserReward({
          ...payload,
        });

        if (res.status === 200) {
          const { userRewards, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            userRewards: _uniqBy(userRewards, "idx"),
            theUserReward: {},
            userReward_searchParams: payload,
            userReward_totalCnt: totalCnt,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _fetchChain(payload: any) {
  return await getChainsApi(payload);
}
async function _findChain(payload: any) {
  return await getChainInfoApi(payload);
}
async function _fetchExchange(payload: any) {
  return await getExchangesApi(payload);
}
async function _fetchToken(payload: any) {
  return await getTokensApi(payload);
}
async function _findToken(payload: any) {
  return await getTokenInfoApi(payload);
}
async function _fetchNft(payload: any) {
  return await getNftsApi(payload);
}
async function _findNft(payload: any) {
  return await getNftInfoApi(payload);
}
async function _fetchUserNft(payload: any) {
  return await getUserNftsApi(payload);
}
async function _fetchUserGT(payload: any) {
  return await getUserGTsApi(payload);
}
async function _fetchUserReward(payload: any) {
  return await getUserRewardsApi(payload);
}
