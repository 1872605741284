export default {
  info: {
    name: "ZeroQuest",
    title: "ZeroQuest WALLET",
  },
  common: {
    // S Footer
    contact: "Contact",
    docs: "Docs",
    audits: "Audit",
    // E Footer
    undefined: "-",
    success: "Success",
    noData: "No Data",
    price: "Price",
    contractAddress: "Contract Address",
    description: "Description",
    id: "ID",
    balance: "Balance",
    status: "Status",
    congratulations: "Congratulations",
    login: "Login In",
    loading: "Loading",
    logout: "Logout",
  },

  board: {
    number: "number",
    session_id: "sessionID",
    id: "ID",
    uid: "uid",
    vote: "vote",
    name: "name",
    username: "User Name",
    phoneNumber: "tel",
    imageFile: "imageFile",
    email: "email",
    userState: "user state",
    state: "state",
    normalcy: "Normal",
    block: "Block",
    joinDate: "join date",
    list: "list",
    register: "register",
    modify: "modify",
    code: "code",
    delete: "Delete",
    total_search_data: "Total search data: ",
    number_cases: "",
    prev: "prev",
    next: "next",
    no_data: "There is no data matching the search criteria.",
    type: "type",
    title: "title",
    topic: "topic",
    desc: "content",
    hide: "hide",
    display: "display",
    visible: "show",
    invisible: "hide",
    createdDate: "registration date",
    endDate: "end date",
    updateDate: "update date",
    referralBalance: "referral balance",
    shareCount: "share count",
    from: "from",
    to: "to",
    referral: "referral",
    notification: "Notice",
    guide: "guide",
    emergency: "emergency",
    memo: "memo",
    senderAidName: "senderAidName",
    address: "address",
    chain: "chain",
    url: "URL",
    token_name: "token name",
    symbol: "symbol",
    nft_symbol: "NFT symbol",
    decimals: "number of digits",
    priceDecimals: "price digits",
    icon: "icon",
    Market: "exchange",
    pairKey: "exchange exchange pairkey",
    exchange: "exchange",
    priceUsdt: "USDT fixed price",
    priceKrw: "KRW fixed price",
    contractAddress: "Contract Address",
    nft: "NFT",
    root: "root",
    point: "point",
    token: "token",
    url_img: "Image",
    link: "link address",
    width: "width",
    height: "height",
    Code: "Code",
    chainId: "network",
    txId: "TxId",
    blockNumberConfirm: "Block Number",
    status: "status",
    typeTransaction: "Transaction type",
    method: "method",
    gasPrice: "gas price",
    Value: "count",
    txIdSend: "txIdSend",
    fromTxId: "From Txid",
    fromSymbol: "sent coin",
    fromAddress: "from address",
    fromValue: "Number sent",
    fromFee: "fee sent",
    toTxId: "To Txid",
    toSymbol: "received coins",
    toAddress: "received address",
    toValue: "received number",
    toFee: "fee received",
    normal: "Normal",
    nft_on: "On Nft",
    gameNft_on: "On GameNFT",
    impossible: "impossible",
    possible: "possible",
    description: "Description",
    descImage: "descImage",
    inputImage: "inputImage",
    buySymbol: "if available for purchase",
    buyPrice: "buy price",
    metaData: "metadata",
    cid: "category",
    multiBuy: "Duplicate purchase",
    image: "image",
    category: "category",
    Balance: "Amount",
    enable: "whether to enable",
    activate: "activate",
    disabled: "disabled",
    tokenId: "Token ID",
    nftName: "NFT Name",
    gameToken: "game token",
    gameKey: "game key",
    reward: "reward",
    isApply: "whether to apply",
    waiting: "waiting",
    complete: "complete",
    cancel: "cancel",
    senderName: "registration identifier",
    balance: "balance",
    makerAidName: "Last Modified Manager",
    delete_confirm: "Are you sure you want to delete?",
    cancel_confirm: "Are you sure you want to cancel?",
    create: "create",
    exist: "exist",
    confirm: "confirm",
    finish: "finish",
    terms: "terms",
    agreement: "agreement",
    disagree: "disagree",
    termsAt: "Agreement date",
    search: {
      period_search_setting: "Period search setting",
      search_start_date: "Search Period Start Date",
      search_end_date: "Search Period End Date",
    },
    write: {
      id: "Account ID",
      pwd: "account password",
      pwd_check: "Check your account password",
      name: "Account Name",
      tel: "Contacts",
      email: "email",
      status: "status",
      token: "token",
      type: "type",
      hide: "hide",
      display: "display",
      title: "title",
      topic: "topic",
      endDate: "end date",
      desc: "content",
      chain: "chain",
      url: "URL",
      token_name: "token name",
      symbol: "symbol",
      nft_symbol: "NFT symbol",
      decimals: "Coin decimal places",
      priceDecimals: "Price Decimals",
      icon: "icon",
      exchange: "exchange",
      pairKey: "exchange pairkey",
      priceUsdt: "USDT fixed price",
      priceKrw: "KRW fixed price",
      contractAddress: "Contract Address",
      url_img: "Image URL",
      link: "link address",
      width: "width",
      height: "height",
      Code: "Code",
      cid: "category",
      multiBuy: "Duplicate purchase",
      description: "Description",
      descImage: "descImage",
      inputImage: "inputImage",
      buySymbol: "if available for purchase",
      buyPrice: "buy price",
      metaData: "metadata",
      senderName: "registration identifier",
      balance: "balance",
      user_check: "User Check",
      callbackUrl: "App company data interworking callback URL",
      andPackagename: "Android package name",
      andDeeplink: "Android deep link",
      iosPackagename: "IOS package name",
      iosDeeplink: "IOS deep link",
      selectFile: "Select File",
      placeholder: {
        id: "Please enter your account ID.",
        pwd: "Please enter your account password.",
        pwd_check:
          "Account passwords are not the same. Please check your password.",
        name: "Please enter your account name.",
        tel: "Please enter your contact number.",
        email: "Please enter your email.",
        status: "Please select a status.",
        token: "Please select a token.",
        type: "Please select a type.",
        hide: "Choose hide.",
        display: "Choose display.",
        title: "Please enter a title.",
        desc: "Please enter your text.",
        memo: "Enter the memo content.",
        cur_password: "Please enter the current fold.",
        new_password: "Enter the focus to edit.",
        cf_password: "Please re-enter the focus to edit.",
        chain: "Please enter a chain name.",
        chain_select: "Please select a chain.",
        url: "Please enter a URL",
        token_name: "Please enter a token name",
        symbol: "Enter a symbol name.",
        nft_symbol: "Enter a NFT symbol name.",
        decimals: "Enter the number of decimal places for your coin.",
        priceDecimals:
          "Please enter the number of decimal places for the price.",
        icon: "Enter the icon image url.",
        exchange: "Please enter a exchange name.",
        exchange_select: "Please select a exchange.",
        pairKey: "Please enter a pair key.",
        priceUsdt: "Enter a price in USDT fixed price.",
        priceKrw: "Please enter a KRW fixed price.",
        contractAddress: "Enter the contract address.",
        url_img: "Enter the image URL.",
        link: "Enter the link address.",
        width: "Enter a banner",
        height: "Please enter the banner height.",
        code: "Enter your code",
        description: "Please enter a description.",
        descImage: "Please enter a description image URL.",
        inputImage: "Please submit your image file.",
        buySymbol: "Please enter the length of time required to purchase.",
        buyPrice: "Please enter a purchase price.",
        metaData: "Please enter your metadata.",
        balance:
          "Points are deducted if the amount is negative when entering the amount.",
        senderName: "Enter sender name",
        user_check: "Enter the user email and confirm the user.",
        callbackUrl:
          "If you need data linkage with the app company, please enter the callback URL (provided by the company)",
        andPackagename: "Enter the Android package name if you have one.",
        andDeeplink: "Enter an Android deep link if one exists.",
        iosPackagename: "Enter the IOS package name if it exists.",
        iosDeeplink: "If you have an iOS deep link, please enter it.",
      },
      process: {
        register_complete: "Registration has been completed.",
        modify_complete: "The modification has been completed.",
        delete_complete: "Delete complete.",
        register_failed: "Registration failed.",
        modify_failed: "The modification failed.",
        delete_failed: "Delete failed.",
        user_confirmation: "User has been verified.",
        change_complete: "The change has been completed.",
        change_failed: "The change failed.",
      },
      error: {
        id_length: "Please enter an account ID between 5 and 15 characters.",
        pwd_length:
          "Please enter an account password of at least 5 characters..",
        pwd_check: "Account password not displayed. Please check your postik.",
        decimals_number: "Please enter only numbers for coin decimal places.",
        priceDecimals_number:
          "Please enter only numeric decimal places for the price.",
        email_check: "Email is not verified.",
      },
    },
  },

  route: {
    Home: "Home",
    About: "About",
    // DEPTH_0 menus (Parent Node)
    P_RootManagent: "Admin Menu",
    P_AppManagent: "Management Menu",
    P_WalletManagent: "Wallet Management",
    P_GroupManagent: "Group Management",
    P_TransmissionDetails: "Transmission Details",
    P_BuyCoins: "Buy Coins",
    P_Config: "Configuration",
    // DEPTH_1 menus (Child node)
    // RootManagent
    C_AdminList: "Admin Accounts",
    C_Notice: "Notice",
    C_ChainInfo: "Chain Info",
    C_TokenInfo: "Token Info",
    C_Memo: "Memo",
    C_UserList: "User Accounts",
    // AppManager
    C_Banner: "Banner Management",
    C_Category: "Category Management",
    C_NftInfo: "NFT Management",
    C_UserPoint: "User Point Management",
    C_UserNftList: "User NFT List",
    C_UserGameTokenList: "List of user game tokens",
    C_UserRewardsList: "User Rewards List",
    C_InfoReferral: "Info_Referral",
    C_UserReferral: "User_Referral",
    // TransmissionDetails
    C_TransitionHistory: "Transition Information",
    C_SwapTransactions: "Register swap information",
    //GroupManager
    C_Group_UserList: "Group user accounts",
    // BuyCoins
    C_UserTokensList: "List of user tokens",
    C_WithdrwalHistory: "Withdrawal History",
    //Config
    C_AccountInfo: "Change Info",
    C_Password: "Change Password",
    Battle: "Battle",
    BattleSession: "BattleSession",
    BattleContents: "BattleContents",
  },

  message: {
    sorryChecking: "The system is being checked.",
  },
};
