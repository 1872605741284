import { computed } from "vue";
import { getMemosApi, delMemoApi } from "@/api/sys/admin";
import _uniqBy from "lodash/unionBy";
import LS from "@/utils/localStorage";
import store from "@/store";
import common from "@/common/common";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    memos: [],
    totalCnt: 0,
    searchParams: {},
  },
  getters: {
    memos: (state: Nullable): Array<any> => state.memos,
    totalCnt: (state: Nullable): number => state.totalCnt,
    searchParams: (state: Nullable): Array<any> => state.searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    // 메모 검색
    async searchMemos(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchMemo({
          ...payload,
        });

        if (res.status === 200) {
          const { memos, totalCnt } = res.data;

          state.commit("updateStatus", {
            memos: _uniqBy(memos, "idx"),
            totalCnt: totalCnt,
            searchParams: {
              title: payload.title,
            },
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          memos: [],
        });
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _fetchMemo(payload: any) {
  return await getMemosApi(payload);
}
