import http from "./http";

enum Api {
  GetSessions = "/admin/sessions",
  GetSessionInfo = "/admin/sessionInfo",
  AddSession = "/admin/sessionAdd",
  EditSession = "/admin/sessionEdit",
  GetContents = "/admin/contents",
  DeleteContents = "/admin/deleteContent",
}

/**
 * @description: get sessions data api
 */

export async function deleteContents(deleteIdx: any) {
  const url = `${Api.DeleteContents}/${deleteIdx}`;
  return await http.delete(url);
}

export async function getContentsApi(params: any) {
  console.log("params", params);
  console.log("delete됬니?");
  return await http.post(Api.GetContents, params);
}

export async function getSessionsApi(params: any) {
  return await http.post(Api.GetSessions, params);
}

/**
 * @description: get session data info api
 */

export async function getSessionInfoApi(params: any) {
  return await http.post(Api.GetSessionInfo, params);
}

/**
 * @description: add session api
 */

export async function addSessionApi(params: any) {
  return await http.post(Api.AddSession, params);
}

/**
 * @description: edit session api
 */

export async function editSessionApi(params: any) {
  return await http.post(Api.EditSession, params);
}
