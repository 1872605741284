import { computed } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "@/views/sys/login/LoginView.vue";
import store from "@/store/index";

const myAccessToken = computed(() => {
  return store.getters["app/myAccessToken"];
});

const chkAccessExpiresIn = computed(() => {
  return store.getters["app/ChkAccessExpiresIn"];
});

const myAid = computed(() => {
  return store.getters["admin/myAid"];
});

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/adminlist",
    name: "adminList",
    component: () =>
      import(
        /* webpackChunkName: "adminList" */ "../views/admin/AdminListView.vue"
      ),
  },
  {
    path: "/notice",
    name: "noticeList",
    component: () =>
      import(
        /* webpackChunkName: "noticeList" */ "../views/admin/NoticeListView.vue"
      ),
  },
  {
    path: "/chaininfo",
    name: "chainInfoList",
    component: () =>
      import(
        /* webpackChunkName: "chainInfoList" */ "../views/admin/ChainInfoListView.vue"
      ),
  },
  {
    path: "/tokeninfo",
    name: "tokenInfoList",
    component: () =>
      import(
        /* webpackChunkName: "tokenInfoList" */ "../views/admin/TokenInfoListView.vue"
      ),
  },
  {
    path: "/memo",
    name: "memoList",
    component: () =>
      import(
        /* webpackChunkName: "memoList" */ "../views/admin/MemoListView.vue"
      ),
  },
  {
    path: "/userlist",
    name: "userList",
    component: () =>
      import(
        /* webpackChunkName: "userList" */ "../views/admin/UserListView.vue"
      ),
  },
  {
    path: "/banner",
    name: "bannerList",
    component: () =>
      import(
        /* webpackChunkName: "bannerList" */ "../views/admin/BannerListView.vue"
      ),
  },
  {
    path: "/category",
    name: "categoryList",
    component: () =>
      import(
        /* webpackChunkName: "categoryList" */ "../views/admin/CategoryListView.vue"
      ),
  },
  {
    path: "/nft_info",
    name: "nftList",
    component: () =>
      import(
        /* webpackChunkName: "nftList" */ "../views/admin/NFTListView.vue"
      ),
  },
  {
    path: "/user_point",
    name: "userPointList",
    component: () =>
      import(
        /* webpackChunkName: "userPointList" */ "../views/admin/UserPointListView.vue"
      ),
  },
  {
    path: "/user_nft",
    name: "userNftList",
    component: () =>
      import(
        /* webpackChunkName: "userNftList" */ "../views/admin/UserNFTListView.vue"
      ),
  },
  // {
  //   path: "/user_gametoken",
  //   name: "userGTList",
  //   component: () =>
  // import(/* webpackChunkName: "adminList" */ "../views/admin/HomeView.vue"),
  // },
  {
    path: "/user_reward",
    name: "userRewardList",
    component: () =>
      import(
        /* webpackChunkName: "userRewardList" */ "../views/admin/UserRewardListView.vue"
      ),
  },
  {
    path: "/transition_history",
    name: "transitionHistoryList",
    component: () =>
      import(
        /* webpackChunkName: "transitionHistoryList" */ "../views/admin/TransHistoryListView.vue"
      ),
  },
  {
    path: "/swap_transactions",
    name: "swapTransactionsList",
    component: () =>
      import(
        /* webpackChunkName: "swapTransactionsList" */ "../views/admin/SwapTransactionsListView.vue"
      ),
  },
  {
    path: "/password",
    name: "password",
    component: () =>
      import(
        /* webpackChunkName: "password" */ "../views/setting/PasswordView.vue"
      ),
  },
  {
    path: "/battleSession",
    name: "battleSession",
    component: () =>
      import(
        /* webpackChunkName: "battleSession" */ "../views/admin/BattleSession.vue"
      ),
  },
  {
    path: "/battleContents",
    name: "battleContents",
    component: () =>
      import(
        /* webpackChunkName: "battleContents" */ "../views/admin/BattleContents.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter(to: Nullable, from: Nullable, next: Nullable) {
      if (myAccessToken.value != "" && !chkAccessExpiresIn.value) {
        next({ name: "home" });
      } else {
        next();
      }
    },
  },

  {
    path: "/UserReferral",
    name: "UserReferral",
    component: () =>
      import(
        /* webpackChunkName: "WmuContents" */ "@/views/admin/UserReferral.vue"
      ),
  },
  {
    path: "/InfoReferral",
    name: "InfoReferral",
    component: () =>
      import(
        /* webpackChunkName: "WmuContents" */ "@/views/admin/InfoReferral.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to: Nullable, from: Nullable, next: Nullable) => {
  if (to.name === "login") {
    next();
  } else {
    if (myAccessToken.value == "" || chkAccessExpiresIn.value) {
      relogin();
    } else if (myAid.value == "" || myAid.value == 0) {
      store.commit("app/UPDATE_LOADING", false);
      await store.dispatch("admin/getInfo");
      if (myAid.value != "" || myAid.value != 0) {
        next();
      } else {
        relogin();
      }
    } else {
      next();
    }
  }
});

function relogin() {
  store.commit("app/RESET_INFO");
  router.push("login");
}

export default router;
