import http from "./http";

enum Api {
  GetNotices = "/admin/notices",
  GetNoticeInfo = "/admin/noticeInfo",

  AddNotice = "/admin/noticeAdd",
  EditNotice = "/admin/noticeEdit",
}

/**
 * @description: get notices data info api
 */

export async function getNoticesApi(params: any) {
  return await http.post(Api.GetNotices, params);
}

/**
 * @description: get notice data info api
 */

export async function getNoticeInfoApi(params: any) {
  return await http.post(Api.GetNoticeInfo, params);
}

/**
 * @description: add admin api
 */

export async function addNoticeApi(params: any) {
  return await http.post(Api.AddNotice, params);
}

/**
 * @description: edit admin api
 */

export async function editNoticeApi(params: any) {
  return await http.post(Api.EditNotice, params);
}
