import { computed } from "vue";
import _uniqBy from "lodash/unionBy";
import store from "@/store";
import common from "@/common/common";
import {
  getBannerInfoApi,
  getBannersApi,
  // deleteBannersApi,
  getCategoriesApi,
  getCategoryInfoApi,
  getInfoReferralApi,
  getUserReferralApi,
} from "@/api/sys/management";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    contents: [],
    infoReferral: [],
    infoReferral_totalCnt: 0,
    userReferral: [],
    userReferral_totalCnt: 0,
    banners: [],
    theBanner: {},
    banner_totalCnt: 0,
    banner_searchParams: {
      type: 0,
      name: "",
      url: "",
      link: "",
      hide: 0,
      start_day: "",
      end_day: "",
      inputImage: "",
    },
    categories: [],
    theCategory: {},
    category_totalCnt: 0,
    category_searchParams: {
      code: "",
      name: "",
      hide: 0,
      start_day: "",
      end_day: "",
    },

    userReferral_searchParams: {
      from: "",
      to: "",
      referral: "",
      point: "",
      start_day: "",
      end_day: "",
    },
    infoReferral_searchParams: {
      name: "",
      email: "",
      referralBalance: "",
      shareCount: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    banners: (state: Nullable): Array<any> => state.banners,
    theBanner: (state: Nullable): Array<any> => state.theBanner,
    banner_totalCnt: (state: Nullable): number => state.banner_totalCnt,
    banner_searchParams: (state: Nullable): Array<any> =>
      state.banner_searchParams,
    categories: (state: Nullable): Array<any> => state.categories,
    theCategory: (state: Nullable): Array<any> => state.theCategory,
    category_totalCnt: (state: Nullable): number => state.category_totalCnt,
    category_searchParams: (state: Nullable): Array<any> =>
      state.category_searchParams,
    userReferral: (state: Nullable) => state.userReferral,
    userReferral_totalCnt: (state: Nullable): number =>
      state.userReferral_totalCnt,
    infoReferral: (state: Nullable) => state.infoReferral,
    infoReferral_totalCnt: (state: Nullable): number =>
      state.infoReferral_totalCnt,
    userReferral_searchParams: (state: Nullable): Array<any> =>
      state.userReferral_searchParams,
    infoReferral_searchParams: (state: Nullable): Array<any> =>
      state.infoReferral_searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    // 배너정보 검색
    async searchBanners(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchBanner({
          ...payload,
        });

        if (res.status === 200) {
          const { banners, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            banners: _uniqBy(banners, "idx"),
            theBanner: {},
            banner_totalCnt: totalCnt,
            banner_searchParams: {
              type: payload.type,
              name: payload.name,
              url: payload.url,
              link: payload.link,
              hide: payload.hide,
              start_day: payload.start_day,
              end_day: payload.end_day,
              inputImage: payload.inputImage,
            },
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          banners: [],
        });
      } finally {
        state.commit("updateStatus", {
          theBanner: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //특정 배너 정보 검색
    async findBanner(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findBanner({
          ...payload,
        });

        if (res.status === 200) {
          const { banner } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theBanner: banner,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theBanner: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
    // 카테고리정보 검색
    async searchCategories(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchCategories({
          ...payload,
        });

        if (res.status === 200) {
          const { categories, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            categories: _uniqBy(categories, "idx"),
            theCategory: {},
            category_totalCnt: totalCnt,
            category_searchParams: {
              code: payload.code,
              name: payload.name,
              hide: payload.hide,
              start_day: payload.start_day,
              end_day: payload.end_day,
            },
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          categories: [],
        });
      } finally {
        state.commit("updateStatus", {
          theCategory: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //특정 배너 정보 검색
    async findCategory(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findCategory({
          ...payload,
        });

        if (res.status === 200) {
          const { category } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theCategory: category,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theCategory: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
    //referral
    async userReferral(state: Nullable, payload: any) {
      console.log("payload", payload);
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        //payload와 동일한 새 객체를 복사하여 전달한다.
        //이 payload의 변동사항이 원본에 영향을 미치지 않는다.
        const res = await _findUserReferral({
          ...payload,
        });
        console.log("userReferral에 payload는", payload);
        if (res.status === 200) {
          const { data, totalCnt } = res.data;
          console.log("res.data(userReferral에서)는", res.data);
          state.commit("updateStatus", {
            ...state.state,
            userReferral: data,
            userReferral_totalCnt: totalCnt,
            userReferral_searchParams: {
              from: payload.from,
              to: payload.to,
              referral: payload.referral,
              point: payload.point,
              start_day: payload.start_day,
              end_day: payload.end_day,
            },
            //theMarket: data[3], //이 데이터 값을 바꿔야 됨
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          markets: [],
        });
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //InfoReferral
    async InfoReferral(state: Nullable, payload: any) {
      console.log("11payloadpayloadpayload22", payload);
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        //payload와 동일한 새 객체를 복사하여 전달한다.
        //이 payload의 변동사항이 원본에 영향을 미치지 않는다.
        const res = await _findInfoReferral({
          ...payload,
        });

        if (res.status === 200) {
          const { data, totalCnt } = res.data;
          console.log("res.data(infoReferral에서)는", res.data);

          state.commit("updateStatus", {
            ...state.state,
            infoReferral: data,
            infoReferral_totalCnt: totalCnt,
            infoReferral_searchParams: {
              name: payload.name,
              email: payload.email,
              referralBalance: payload.referralBalance,
              shareCount: payload.shareCount,
              start_day: payload.start_day,
              end_day: payload.end_day,
            },
            //theMarket: data[3], //이 데이터 값을 바꿔야 됨
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          markets: [],
        });
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _fetchBanner(payload: any) {
  return await getBannersApi(payload);
}
async function _findBanner(payload: any) {
  return await getBannerInfoApi(payload);
}
async function _fetchCategories(payload: any) {
  return await getCategoriesApi(payload);
}
async function _findCategory(payload: any) {
  return await getCategoryInfoApi(payload);
}

async function _findUserReferral(payload: any) {
  return await getUserReferralApi(payload);
}

async function _findInfoReferral(payload: any) {
  return await getInfoReferralApi(payload);
}
