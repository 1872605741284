import http from "./http";

enum Api {
  GetUsers = "/admin/users",
  GetPermCode = "/getPermCode",
  GetUserInfo = "/admin/userInfo",
  EditUser = "/admin/userEdit",

  GetUserPoints = "/admin/userPoints",
  AddUserPoint = "/admin/userPointAdd",
  DelUserPoint = "/admin/userPointDel",
}

/**
 * @description: get users data info api
 */

export async function getUsersApi(params: any) {
  return await http.post(Api.GetUsers, params);
}

/**
 * @description: get user data info api
 */

export async function getUserInfoApi(params: any) {
  return await http.post(Api.GetUserInfo, params);
}

/**
 * @description: edit user api
 */

export async function editUserApi(params: any) {
  return await http.post(Api.EditUser, params);
}

/**
 * @description: get userPoints data info api
 */

export async function getUserPointsApi(params: any) {
  return await http.post(Api.GetUserPoints, params);
}

/**
 * @description: add userPoint api
 */

export async function addUserPointApi(params: any) {
  return await http.post(Api.AddUserPoint, params);
}

/**
 * @description: delete userPoint api
 */

export async function delUserPointApi(params: any) {
  return await http.post(Api.DelUserPoint, params);
}
