import { computed } from "vue";
import { getUserInfoApi, getUserPointsApi, getUsersApi } from "@/api/sys/user";
import common from "@/common/common";
import _uniqBy from "lodash/unionBy";
import store from "@/store";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    users: [],
    theUser: {},
    totalCnt: 0,
    searchParams: {
      username: "",
      email: "",
      terms: "",
      status: "",
      start_day: "",
      end_day: "",
    },
    userPoints: [],
    theUserPoint: {},
    userPoint_totalCnt: 0,
    userPoint_searchParams: {
      username: "",
      symbol: "",
      senderName: "",
      senderAidName: "",
      isApply: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    users: (state: Nullable): Array<any> => state.users,
    totalCnt: (state: Nullable): number => state.totalCnt,
    theUser: (state: Nullable): Array<any> => state.theUser,
    searchParams: (state: Nullable): Array<any> => state.searchParams,
    userPoints: (state: Nullable): Array<any> => state.userPoints,
    userPoint_totalCnt: (state: Nullable): number => state.userPoint_totalCnt,
    theUserPoint: (state: Nullable): Array<any> => state.theUserPoint,
    userPoint_searchParams: (state: Nullable): Array<any> =>
      state.userPoint_searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    //특정 유저 정보 검색
    async findUser(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findUser({
          ...payload,
        });

        if (res.status === 200) {
          const { user } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theUser: user,
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theUser: {},
        });
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    //조건에 맞는 유저 정보검색
    async searchUsers(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchUser({
          ...payload,
        });

        if (res.status === 200) {
          const { users, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            searchParams: payload,
            users: _uniqBy(users, "uid"),
            theUser: {},
            totalCnt: totalCnt,
          });
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          users: [],
        });
      } finally {
        state.commit("updateStatus", {
          theUser: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },

    // 유저 포인트 정보 검색
    async searchUserPoints(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchUserPoint({
          ...payload,
        });

        if (res.status === 200) {
          const { userPoints, totalCnt } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            userPoints: _uniqBy(userPoints, "idx"),
            theUserPointeNft: {},
            userPoint_totalCnt: totalCnt,
            userPoint_searchParams: payload,
          });
          store.commit("app/UPDATE_LOADING", false);
        }
      } catch (e: any) {
        common.axiosCatch(e);
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _fetchUser(payload: any) {
  return await getUsersApi(payload);
}

async function _findUser(payload: any) {
  return await getUserInfoApi(payload);
}

async function _fetchUserPoint(payload: any) {
  return await getUserPointsApi(payload);
}
