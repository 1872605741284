import { computed } from "vue";
import { getAdminInfoApi, getAdminsApi, getInfoApi } from "@/api/sys/admin";
import { GetAdminInfoModel } from "@/api/sys/model/userModel";
import _uniqBy from "lodash/unionBy";
import LS from "@/utils/localStorage";
import store from "@/store";
import common from "@/common/common";

const appLoading = computed(() => {
  return store.getters["app/appLoading"];
});

export default {
  namespaced: true,
  state: {
    aid: "" || 0,
    account: "",
    appId: "",
    status: 0,
    name: "",
    tel: "" || 0,
    email: "",
    grade: 0,
    grade1: 0,
    grade2: 0,
    grade3: 0,
    grade4: 0,
    grade5: 0,
    makerAid: "" || 0,
    sessionId: "",
    token: "",
    created_date: Date,
    updated_date: Date,
    admins: [],
    theAdmin: {},
    totalCnt: 0,
    searchParams: {
      name: "",
      email: "",
      status: "",
      tel: "",
      start_day: "",
      end_day: "",
    },
  },
  getters: {
    myAid: (state: Nullable): string => state.aid,
    myAccount: (state: Nullable): string => state.account,
    myAppId: (state: Nullable): string => state.appId,
    loginToken: (state: Nullable): string => state.token,
    loginSession: (state: Nullable): string => state.sessionId,
    admins: (state: Nullable): Array<any> => state.admins,
    theAdmin: (state: Nullable): Array<any> => state.theAdmin,
    totalCnt: (state: Nullable): number => state.totalCnt,
    searchParams: (state: Nullable): Array<any> => state.searchParams,
  },
  mutations: {
    updateStatus(status: any, data: any) {
      Object.keys(data).forEach((key) => {
        status[key] = data[key];
      });
    },
  },
  actions: {
    //특정 관리자 정보 검색
    async findAdmin(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _findAdmin({
          ...payload,
        });

        if (res.status === 200) {
          const { admin } = res.data;

          state.commit("updateStatus", {
            ...state.state,
            theAdmin: admin,
          });
        }
        store.commit("app/UPDATE_LOADING", false);
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          theAdmin: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      } finally {
        //store.commit("app/UPDATE_LOADING", false);
      }
    },
    // 현재 사용자 정보 갖고오기
    async getInfo(state: Nullable) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const mySessionId = store.getters["app/mySessionId"];
        const result = await getInfoApi({ sessionId: mySessionId });
        if (result.status === 200) {
          const loginData: GetAdminInfoModel = result.data.adminInfo;

          const newAdmininfo = {
            aid: loginData.aid,
            account: loginData.account,
            appId: loginData.appId,
            tel: loginData.tel,
            status: loginData.status,
            name: loginData.name,
            grade: loginData.grade,
            grade1: loginData.grade1,
            grade2: loginData.grade2,
            grade3: loginData.grade3,
            grade4: loginData.grade4,
            grade5: loginData.grade5,
            sessionId: loginData.sessionId,
            makerAid: loginData.makerAid,
            created_date: loginData.created_date,
            updated_date: loginData.updated_date,
            token: loginData.accessToken,
          };
          state.commit("updateStatus", newAdmininfo);

          return { result: true, message: "success" };
        }
      } catch (e: any) {
        let message = "";
        message = common.axiosCatch(e);

        return { result: false, message: message };
      } finally {
        store.commit("app/UPDATE_LOADING", false);
      }
    },
    // 관리자 계정정보 검색
    async searchAdmins(state: Nullable, payload: any) {
      if (appLoading.value) {
        return;
      } else {
        store.commit("app/UPDATE_LOADING", true);
      }
      try {
        const res = await _fetchAdmin({
          ...payload,
        });

        if (res.status === 200) {
          const { admins, totalCnt } = res.data;

          state.commit("updateStatus", {
            admins: _uniqBy(admins, "aid"),
            theAdmin: {},
            searchParams: payload,
            totalCnt: totalCnt,
          });
          // const total = parseInt(totalCnt, 10);
          // const pagelenth = Math.ceil(total / 10);
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          store.commit("app/RESET_INFO");
        }
        state.commit("updateStatus", {
          admins: [],
        });
      } finally {
        state.commit("updateStatus", {
          theAdmin: {},
        });
        store.commit("app/UPDATE_LOADING", false);
      }
    },
  },
};
async function _findAdmin(payload: any) {
  return await getAdminInfoApi(payload);
}
async function _fetchAdmin(payload: any) {
  return await getAdminsApi(payload);
}
